<template>
    <div class="ProductLibraryDetail">
        <el-page-header @back="$router.go(-1)" content="产品详情"></el-page-header>
        <!-- 商品信息 -->
        <div class="container f-c">
            <!-- 轮播图 -->
            <div class="carousel f-fr-jc">
                <el-image v-show="proUrlList[proUrlList_index] && proUrlList_index != -1" class="elimg"
                    :src="proUrlList[proUrlList_index]" :preview-src-list="proUrlList" alt=""></el-image>
                <video v-show="videoUrl && proUrlList_index == -1" controls :src="videoUrl" type="video/mp4" width="450px"
                    height="450px" muted ref="upvideo">
                    <source :src="videoUrl" type="video/mp4">
                    您的浏览器不支持 video 标签。
                </video>
                <div class="f-c-b carousel_box">
                    <div class="carousel_item f-w">
                        <video class="video" v-show="videoUrl" :src="videoUrl" type="video/mp4" width="80px" height="80px"
                            muted ref="upvideo" @click="proUrlList_index = -1">
                        </video>
                        <div class="item" v-for="(item, index) in proUrlList" :key="index">
                            <img :src="item" alt="" @click="proUrlList_index = index"
                                :style="{ 'border': [proUrlList_index == index ? '1px solid #2f9df8' : 'none'] }">
                        </div>
                    </div>
                    <img class="left" src="@/assets/left.png" alt="" @click="reduction()">
                    <img class="right" src="@/assets/right.png" alt="" @click="add()">
                </div>

            </div>
            <!-- 商品信息 -->
            <div class="info" style="display: flex;justify-content: space-between;">
                <div class="basicInfoBox" style="width: 60%;">

                    <div class="title">
                        <div class="name">{{ productName }}</div>
                    </div>
                    <div class="basicInfo">
                        <div v-for="(item, index) in productType1" :key="index">
                            <div class="f-c" style="width: 100%;"
                                v-if="(item.title != '商品简介' && item.title != '商品名称' && item.title != '商品主色' && item.title != '颜色名称')">
                                <span class="basicInfo_title">{{ item.title }}：</span>
                                <div v-if="item.title == '参考价格'">
                                    <span style="color: #FA0101;"
                                        :style="{ fontSize: (item.value.split('/')[0] == '暂无报价') ? '20px' : '24px' }">{{
                                            item.value.split('/')[0] }}</span>
                                    <span v-if="item.value.split('/')[1]"> / </span>
                                    <span>{{ item.value.split('/')[1] }}</span>
                                </div>
                                <span class="basicInfo_value" v-else>{{ item.value }}</span>
                            </div>
                        </div>
                        <div>
                            <div class="f-c">
                                <span class="basicInfo_title2">产品主色：</span>
                                <div v-if="rgbValue" @click="dialogShowColor = true"
                                    style="height: 40px;width: 70px;border-radius: 5px;cursor: pointer;"
                                    :style="{ 'backgroundColor': rgbValue }"></div>
                                <span v-else class="basicInfo_value2">未设置</span>
                            </div>
                            <div class="f-c">
                                <span class="basicInfo_title2">颜色名称：</span>
                                <span class="basicInfo_value2">{{ colorStr }}</span>
                            </div>
                        </div>
                        <div class="f-c">
                            <div class="pageView mr-50"><i class="el-icon-view"></i> 浏览 {{ hitsCount }}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- 颜色预览 -->
            <el-dialog title="颜色预览" :visible.sync="dialogShowColor" width="710px">
                <div class="color-gb f-c-c" :style="{ background: rgbValue }">
                    <div>RGB：{{ rgbValue }}</div>
                    <img class="copy" src="@/assets/copy.png" alt="" @click="copy()">
                </div>

                <div style="text-align: center;">色彩预览会因终端设备的显示效果产生差异.仅供参考使用</div>
            </el-dialog>
        </div>
        <!-- 商品基本信息 -->
        <div class="productbasicInfo">
            <div class="infoBox">
                <div class="argumentBox">
                    <div class="title">参数信息</div>
                    <div v-if="Productparameter.length != 0" class="argument f-w">
                        <div v-for="(item, index) in Productparameter" :key="index">
                            <div class="argument_item" v-if="item.value">
                                <span class="argument_title">{{ item.title }}：</span>
                                <span class="argument_value">{{ item.value }}</span>
                            </div>

                        </div>
                    </div>
                    <div v-else>未设置</div>
                </div>
                <div class="introTextBox">
                    <div class="title">产品简介</div>
                    <div class="introText">{{ introduction }}</div>
                </div>

            </div>
        </div>
        <!-- PlatformSearchSettingsList -->
        <div class="mt-50 otherInfo">
            <div class="title">平台搜索设置</div>
            <div class="mt-30">
                <!-- E5E4E4 -->
                <div class="h-80 f-c otherStyle"
                    :style="{ 'border-bottom': [index == 0 ? '1px solid #E5E4E4' : 'none'], 'border-radius': [index == 0 ? '0px 10px 0 0' : '0px 0px 10px 10px'] }"
                    v-for="(item, index) in PlatformSearchSettingsList" :key="index">
                    <div class="pl-25 w-100">{{ item.title }}</div>
                    <div style="margin-left: 800px;color: #333;">{{ item.value }}</div>
                </div>
            </div>
        </div>
        <div class="mt-30 otherInfo">
            <div class="title">产品修改记录</div>
            <div class="mt-30">
                <!-- E5E4E4 -->
                <div class="h-80 f-c otherStyle"
                    :style="{ 'border-bottom': [index == 0 ? '1px solid #E5E4E4' : 'none'], 'border-radius': [index == 0 ? '0px 10px 0 0' : '0px 0px 10px 10px'] }"
                    v-for="(item, index) in ProductModifyRecordList" :key="index">
                    <div class="pl-25 w-100">{{ item.title }}</div>
                    <div style="margin-left: 800px;color: #333;">{{ item.value }}</div>
                </div>
            </div>
        </div>
        <div class="mt-30 otherInfo">
            <div class="title">库存信息</div>
            <div class="mt-30 inventory" v-if="enterpriseStateInfo.enterpriseLevel != 0">
                <div class="count f-c-b">
                    <div class="f-c">
                        <span class="title w-100">库存数量:</span>
                        <span style="margin-left: 800px;color: #333;">{{ inventoryQuantity }}</span>
                    </div>
                    <div class="btn" @click="ModifyInventory" v-if="enterpriseStateInfo.userPermission.i_m == 'W'">修改库存
                    </div>
                </div>
                <div class="record f-c" v-if="enterpriseStateInfo.userPermission.i_m != 'D'">
                    <span class="title f1">库存记录:</span>
                    <!-- <span class="f1"> </span> -->
                    <div class="btn" @click="checkInventoryRecords">查看</div>
                </div>
                <!-- <div class="w--100 filter"></div> -->
                <!-- <img style="z-index: 99999999;" src="../../../assets/supply/btn2.png" alt="btn2"> -->
            </div>
            <div class="mt-30 inventory filter" v-else>
                <div class="count f-c-b">
                    <div class="f-c">
                        <span class="title w-100">库存数量:</span>
                    </div>
                    <div class="btn1" v-if="enterpriseStateInfo.userPermission.i_m == 'W'">修改库存
                    </div>
                </div>
                <div class="record f-c" v-if="enterpriseStateInfo.userPermission.i_m != 'D'">
                    <span class="title f1">库存记录:</span>
                    <div class="btn1">查看</div>
                </div>
            </div>
            <img v-if="enterpriseStateInfo.enterpriseLevel == 0" class="filterBtn" src="../../../assets/supply/btn2.png" alt="btn2">
        </div>
        <div class="btns f-c-a" v-if="enterpriseStateInfo.userPermission.p_l == 'W'">
            <div class="ModifyInfo" @click="ModifyInfo">修改产品信息</div>
            <div class="AddSimilar" @click="AddSimilar">添加相似产品</div>
        </div>
    </div>
</template>

<script>
import { UrlDecodeId } from '@/mixins/UrlDecodeMixin'
import { UnitAccount } from "@/utils/attributeValue1Options"
export default {
    name: 'ProductLibraryDetail',
    mixins: [UrlDecodeId],
    data() {
        return {
            title: '产品基本信息',
            productType1: [],
            proUrlList: [],
            proUrlList_index: "",
            rgbValue: "",
            colorStr: "",
            videoUrl: "",
            Productparameter: [],
            PlatformSearchSettingsList: [],
            ProductModifyRecordList: [],
            inventoryQuantity: 0,
            ProductLibraryForm: {},
            addSimilarProductForm: {},
            productInventoryBaseInfo: {},
            enterpriseStateInfo: {},
            productName: '',
            hitsCount: '',
            dialogShowColor: false,
            introduction: '',  // 简介
        }
    },
    created() {
        this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo');
        console.log(this.enterpriseStateInfo);
        this.findEnterpriseProductDetails(this.queryId);
    },
    methods: {
        // 用户查看企业产品列表详情接口
        findEnterpriseProductDetails(productId) {
            let that = this;
            that.$http.findEnterpriseProductDetails({
                lastModifyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                productId
            }).then(res => {
                // hitsCount 浏览量
                // introduction 产品简介
                // valuationCurrency:前单位
                // unitPrice:价格
                // enterpriseId
                let { productType, productName, productNumber, colorStr, unitPrice, valuationCurrency, introduction, inventoryQuantity, productId, hitsCount, enterpriseId,
                    videoUrl, proUrlList, openSearchFlag, shortageFlag, lastModifyUsername, lastModifyDate, img1Url, img2Url, img3Url, img4Url } = res.data,
                    { attributeValue1, attributeValue2, attributeValue3, attributeValue4, attributeValue5, attributeValue6, attributeValue7, attributeValue8,
                        attributeValue9, attributeValue10, attributeValue11, attributeValue12, attributeValue13, attributeValue14, attributeValue15, attributeValue16 } = res.data.productAttribute,
                    productPrice = unitPrice ? (valuationCurrency + (unitPrice ? parseFloat(unitPrice).toFixed(2) : "") + attributeValue1) : "暂无报价", //商品价格
                    rgbValue = res.data.rgbValue != "" ? ("#" + res.data.rgbValue) : "";
                that.rgbValue = rgbValue;
                that.videoUrl = videoUrl;
                that.proUrlList_index = videoUrl ? -1 : 0;
                console.log('colorStr', colorStr, 'colorStr', Boolean(colorStr))
                that.colorStr = colorStr ? colorStr : '未设置';
                console.log(that.colorStr)
                that.introduction = introduction ? introduction : '暂无简介';
                that.proUrlList = proUrlList;
                that.enterpriseId = enterpriseId ?? '';
                that.productName = productName ?? '';
                that.hitsCount = hitsCount ?? '';





                switch (productType) {
                    case 1:
                        that.Productparameter = [
                            {
                                "title": "平均张幅(最小)",
                                "value": attributeValue12 ? attributeValue12 + ` ` + attributeValue13 : '未设置'
                            }, {
                                "title": "平均张幅(最大)",
                                "value": attributeValue16 ? attributeValue16 + ` ` + attributeValue13 : '未设置'
                            }, {
                                "title": "等级",
                                "value": attributeValue8 ? attributeValue8 : '未设置'
                            }, {
                                "title": "工艺",
                                "value": attributeValue7 ? attributeValue7 : '未设置'
                            }, {
                                "title": "材质",
                                "value": attributeValue2 ? attributeValue2 : '未设置'
                            }, {
                                "title": "厚度(最小)",
                                "value": attributeValue3 ? attributeValue3 + ` mm` : '未设置'
                            }, {
                                "title": "厚度(最大)",
                                "value": attributeValue14 ? attributeValue14 + ` mm` : '未设置'
                            }, {
                                "title": "硬度(最小)",
                                "value": attributeValue5 ? attributeValue5 + ` N/mm²` : '未设置'
                            }, {
                                "title": "硬度(最大)",
                                "value": attributeValue15 ? attributeValue15 + ` N/mm²` : '未设置'
                            }, {
                                "title": "可定制花纹",
                                "value": attributeValue9 == "Y" ? "是" : "否"
                            }, {
                                "title": "可定制厚度",
                                "value": attributeValue10 == "Y" ? "是" : "否"
                            }, {
                                "title": "可定制工艺",
                                "value": attributeValue11 == "Y" ? "是" : "否"
                            },
                        ]
                        break;
                    case 2:
                        that.Productparameter = [
                            {
                                "title": "底基类型",
                                "value": attributeValue2 ? attributeValue2 : '未设置'
                            }, {
                                "title": "工艺类型",
                                "value": attributeValue3 ? attributeValue3 : '未设置'
                            }, {
                                "title": "风格类型",
                                "value": attributeValue4 ? attributeValue4 : '未设置'
                            }, {
                                "title": "产品功能",
                                "value": attributeValue5 ? attributeValue5 : '未设置'
                            }, {
                                "title": "厚度",
                                "value": attributeValue6 ? attributeValue6 + ` ` + attributeValue7 : '未设置'
                            }, {
                                "title": "张幅(最小)",
                                "value": attributeValue8 ? attributeValue8 + ` ` + attributeValue10 : '未设置'
                            }, {
                                "title": "张幅(最大)",
                                "value": attributeValue9 ? attributeValue9 + ` ` + attributeValue10 : '未设置'
                            }
                            // , {
                            //     "title": "张幅单位",
                            //     "value": (attributeValue8 || attributeValue9) && attributeValue10 ? attributeValue10 : '未设置'
                            // }
                        ]
                        break;
                    case 3:
                        that.Productparameter = [
                            {
                                "title": "材质",
                                "value": attributeValue2 ? attributeValue2 : '未设置'
                            }, {
                                "title": "工艺",
                                "value": attributeValue3 ? attributeValue3 : '未设置'
                            }, {
                                "title": "尺寸(长)",
                                "value": attributeValue4 ? attributeValue4 + ` ` + attributeValue7 : '未设置'
                            }, {
                                "title": "尺寸(宽)",
                                "value": attributeValue5 ? attributeValue5 + ` ` + attributeValue7 : '未设置'
                            }, {
                                "title": "尺寸(高)",
                                "value": attributeValue6 ? attributeValue6 + ` ` + attributeValue7 : '未设置'
                            }, {
                                "title": "内径",
                                "value": attributeValue12 ? attributeValue12 + ` ` + attributeValue7 : '未设置'
                            },
                            // {
                            //     "title": "尺寸单位",
                            //     "value": attributeValue7 ? attributeValue7 : '未设置'
                            // },
                            {
                                "title": "线粗",
                                "value": attributeValue8 ? attributeValue8 + ` ` + attributeValue9 : '未设置'
                            }, {
                                "title": "重量",
                                "value": attributeValue10 ? attributeValue10 + ` ` + attributeValue11 : '未设置'
                            }
                        ]
                        break;
                    case 4:
                        that.Productparameter = [
                            {
                                "title": "类型",
                                "value": attributeValue2 ? attributeValue2 : '未设置'
                            }, {
                                "title": "材质",
                                "value": attributeValue3 ? attributeValue3 : '未设置'
                            }, {
                                "title": "工艺",
                                "value": attributeValue4 ? attributeValue4 : '未设置'
                            }, {
                                "title": "尺码范围(最小)",
                                "value": attributeValue5 ? attributeValue5 + ` ` + attributeValue7 : '未设置'
                            }, {
                                "title": "尺码范围(最大)",
                                "value": attributeValue6 ? attributeValue6 + ` ` + attributeValue7 : '未设置'
                            },
                            // {
                            //     "title": "尺寸单位",
                            //     "value": attributeValue7 ? attributeValue7 : '未设置'
                            // },
                            {
                                "title": "风格",
                                "value": attributeValue8 ? attributeValue8 : '未设置'
                            }, {
                                "title": "功能",
                                "value": attributeValue9 ? attributeValue9 : '未设置'
                            }, {
                                "title": "前掌(宽)",
                                "value": attributeValue10 ? attributeValue10 + ` ` + attributeValue15 : '未设置'
                            }, {
                                "title": "前掌(高)",
                                "value": attributeValue11 ? attributeValue11 + ` ` + attributeValue15 : '未设置'
                            }, {
                                "title": "后跟(宽)",
                                "value": attributeValue12 ? attributeValue12 + ` ` + attributeValue15 : '未设置'
                            }, {
                                "title": "后跟(高)",
                                "value": attributeValue13 ? attributeValue13 + ` ` + attributeValue15 : '未设置'
                            }, {
                                "title": "腰位(宽)",
                                "value": attributeValue14 ? attributeValue14 + ` ` + attributeValue15 : '未设置'
                            }
                        ]
                        break;
                    case 5:
                        that.Productparameter = [
                            {
                                "title": "材质类型",
                                "value": attributeValue2 ? attributeValue2 : '未设置'
                            }, {
                                "title": "厚度",
                                "value": attributeValue3 ? attributeValue3 + ` ` + attributeValue4 : '未设置'
                            }, {
                                "title": "宽度",
                                "value": attributeValue5 ? attributeValue5 + ` ` + attributeValue6 : '未设置'
                            }, {
                                "title": "最大长度",
                                "value": attributeValue7 ? attributeValue7 + ` ` + attributeValue8 : '未设置'
                            }
                        ]
                        break;
                    case 6:
                        that.Productparameter = [
                            {
                                "title": "材质类型",
                                "value": attributeValue2 ? attributeValue2 : '未设置'
                            }, {
                                "title": "编织类型",
                                "value": attributeValue3 ? attributeValue3 : '未设置'
                            }, {
                                "title": "厚度",
                                "value": attributeValue4 ? attributeValue4 + ` ` + attributeValue5 : '未设置'
                            }, {
                                "title": "宽度",
                                "value": attributeValue6 ? attributeValue6 + ` ` + attributeValue7 : '未设置'
                            }, {
                                "title": "最大长度",
                                "value": attributeValue8 ? attributeValue8 + ` ` + attributeValue9 : '未设置'
                            }
                        ]
                        break;
                    case 7:
                        that.Productparameter = [
                            {
                                "title": "面料",
                                "value": attributeValue2 ? attributeValue2 : '未设置'
                            }, {
                                "title": "适用人群",
                                "value": attributeValue3 ? attributeValue3 : '未设置'
                            }, {
                                "title": "风格",
                                "value": attributeValue4 ? attributeValue4 : '未设置'
                            }, {
                                "title": "功能",
                                "value": attributeValue5 ? attributeValue5 : '未设置'
                            }, {
                                "title": "尺码范围(最小)",
                                "value": attributeValue6 ? attributeValue6 + ` ` + attributeValue8 : '未设置'
                            }, {
                                "title": "尺码范围(最大)",
                                "value": attributeValue7 ? attributeValue7 + ` ` + attributeValue8 : '未设置'
                            }
                        ]
                        break;
                    case 8:
                        that.Productparameter = [
                            {
                                "title": "款式",
                                "value": attributeValue2 ? attributeValue2 : '未设置'
                            }, {
                                "title": "面料",
                                "value": attributeValue3 ? attributeValue3 : '未设置'
                            }, {
                                "title": "里料",
                                "value": attributeValue4 ? attributeValue4 : '未设置'
                            }, {
                                "title": "规格(长)",
                                "value": attributeValue5 ? attributeValue5 + ` ` + attributeValue8 : '未设置'
                            }, {
                                "title": "规格(宽)",
                                "value": attributeValue6 ? attributeValue6 + ` ` + attributeValue8 : '未设置'
                            }, {
                                "title": "规格(高)",
                                "value": attributeValue7 ? attributeValue7 + ` ` + attributeValue8 : '未设置'
                            }, {
                                "title": "容量",
                                "value": attributeValue9 ? attributeValue9 + ` ` + attributeValue10 : '未设置'
                            }
                        ]
                        break;
                }

                that.PlatformSearchSettingsList = [{
                    "title": "开放对外搜索",
                    "value": openSearchFlag == "Y" ? "是" : "否"
                }, {
                    "title": "对外显示缺货",
                    "value": shortageFlag == "Y" ? "是" : "否"
                }];
                that.ProductModifyRecordList = [{
                    "title": "修改人",
                    "value": lastModifyUsername
                }, {
                    "title": "修改时间",
                    "value": lastModifyDate
                }];
                that.inventoryQuantity = inventoryQuantity.toFixed(2)
                that.productType1 = [{
                    "title": "产品类型",
                    "value": UnitAccount.getproductType(productType)
                }, {
                    "title": "参考价格",
                    "value": productPrice
                },
                // {
                //     "title": "产品名称",
                //     "value": productName
                // },
                {
                    "title": "颜色名称",
                    "value": colorStr ? colorStr : '未设置'
                },
                // {
                //     "title": "产品主色",
                //     "value": rgbValue
                // }, 
                {
                    "title": "产品编号",
                    "value": productNumber ? productNumber : '未设置'
                },
                    // {
                    //     "title": "产品简介",
                    //     "value": introduction ? introduction : '暂无简介'
                    // }, 
                    // {
                    //     "title": "浏览量",
                    //     "value": hitsCount ? hitsCount : "0"
                    // }
                ];
                // 修改产品
                that.ProductLibraryForm = {
                    productType,
                    productId,
                    productName,
                    productNumber,
                    colorStr,
                    rgbValue: res.data.rgbValue,
                    unitPrice,
                    valuationCurrency,
                    openSearchFlag,
                    shortageFlag,
                    img1Url,
                    img2Url,
                    img3Url,
                    img4Url,
                    videoUrl,
                    introduction, //产品简介
                    productAttribute: {
                        attributeValue1: (productType == 999 && attributeValue1) ? attributeValue1.split('/')[1] : attributeValue1,
                        // attributeValue1,
                        attributeValue7: (productType == 1 && attributeValue7) ? attributeValue7.split('->') : attributeValue7,
                        attributeValue2: (productType == 1 && attributeValue2) ? attributeValue2.split('->') : attributeValue2,
                        attributeValue3,
                        attributeValue14,
                        attributeValue4,
                        attributeValue5,
                        attributeValue15,
                        attributeValue6,
                        attributeValue9,
                        attributeValue10,
                        attributeValue11,
                        attributeValue12,
                        attributeValue16,
                        attributeValue13,
                        attributeValue8
                    }
                };
                if (productType == 3) {
                    that.ProductLibraryForm.productAttribute.attributeValue3list = (productType == 3 && attributeValue3 && attributeValue3 != '未设置') ? attributeValue3.split('·') : ''
                }
                // 添加相似产品
                that.addSimilarProductForm = {
                    productType,
                    productName,
                    productNumber,
                    colorStr,
                    rgbValue: res.data.rgbValue,
                    unitPrice,
                    valuationCurrency,
                    openSearchFlag,
                    img1Url: '',
                    img2Url: '',
                    img3Url: '',
                    img4Url: '',
                    videoUrl: '',
                    shortageFlag,
                    introduction, //产品简介
                    productAttribute: {
                        // attributeValue1,
                        attributeValue1: (productType == 999 && attributeValue1) ? attributeValue1.split('/')[1] : attributeValue1,
                        attributeValue7: (productType == 1 && attributeValue7) ? attributeValue7.split('->') : attributeValue7,
                        // attributeValue7,
                        attributeValue2: (productType == 1 && attributeValue2) ? attributeValue2.split('->') : attributeValue2,
                        // attributeValue2,
                        attributeValue3,
                        attributeValue14,
                        attributeValue4,
                        attributeValue5,
                        attributeValue15,
                        attributeValue6,
                        attributeValue9,
                        attributeValue10,
                        attributeValue11,
                        attributeValue12,
                        attributeValue16,
                        attributeValue13,
                        attributeValue8
                    }
                };
                if (productType == 3) {
                    that.addSimilarProductForm.productAttribute.attributeValue3list = (productType == 3 && attributeValue3 && attributeValue3 != '未设置') ? attributeValue3.split('·') : ''
                }
                that.productInventoryBaseInfo = {
                    productId,
                    productName,
                    productNumber,
                    inventoryQuantity,
                    productAttribute: {
                        attributeValue2: attributeValue2,
                    }
                }

            })
        },
        // 修改产品信息
        ModifyInfo() {
            this.$store.commit('ProductLibraryForm', this.ProductLibraryForm)
            this.$router.push({
                name: 'ProductModify',
                query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        type: "modify"
                    }))
                }
            })
        },
        // 添加相似产品
        AddSimilar() {
            this.$store.commit('addSimilarProductForm', this.addSimilarProductForm)
            this.$router.push({
                name: 'ProductLibraryAdd',
                query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        type: "AddSimilar"
                    }))
                }
            })
        },
        // 修改库存
        ModifyInventory() {
            this.$store.commit('productInventoryBaseInfo', this.productInventoryBaseInfo)
            this.$router.push({
                name: 'ModifyInventory'
            })
        },
        // 查看库存记录
        checkInventoryRecords() {
            this.$router.push({
                name: 'InventoryRecords',
                query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        type: 'search',
                        searchValue: this.ProductLibraryForm.productName
                    }))
                }
            })
        },
        reduction() {
            let that = this,
                { proUrlList_index, proUrlList, videoUrl } = this;

            if (videoUrl) {
                if (proUrlList_index > -1) {
                    that.proUrlList_index = proUrlList_index - 1;
                } else {
                    that.proUrlList_index = proUrlList.length - 1;
                }
            } else {
                if (proUrlList_index > 0) {
                    that.proUrlList_index = proUrlList_index - 1;
                } else {
                    that.proUrlList_index = proUrlList.length - 1;
                }
            }
        },
        add() {
            let that = this,
                { proUrlList_index, proUrlList, videoUrl } = this;

            if (videoUrl) {
                if (proUrlList_index < proUrlList.length - 1) {
                    that.proUrlList_index = proUrlList_index + 1;
                } else {
                    that.proUrlList_index = -1;
                }
            } else {
                if (proUrlList_index < proUrlList.length - 1) {
                    that.proUrlList_index = proUrlList_index + 1;
                } else {
                    that.proUrlList_index = 0;
                }
            }
        },
        // 复制
        copy() {
            let that = this;
            that.$copyText(this.rgbValue).then(
                function (e) {
                    console.log(e)
                    that.$common.message('复制成功', 'success')
                },
                function (e) {
                    console.log(e)
                    that.$common.message('复制失败', 'error')
                }
            );
        },
    },

}

</script>
<style lang='less' scoped>
.ProductLibraryDetail {
    margin: auto;

    .container {
        .carousel {
            width: 450px;
            height: 560px;
            margin-left: 40px;

            .elimg {
                width: 450px;
                height: 450px;
                border-radius: 10px;
            }

            .carousel_box {
                width: 100%;
                position: relative;

                img {
                    width: 30px;
                    height: 30px;
                }

                .carousel_item {
                    width: 100%;
                    height: 110px;
                    margin-top: 10px;

                    .video {
                        margin-right: 5px;
                        cursor: pointer;

                    }

                    .item {
                        margin-right: 10px;
                        cursor: pointer;
                    }

                    .select {
                        box-shadow: 1px -3px 14px 0px rgba(0, 0, 0, 0.1);
                    }

                    img {
                        width: 80px;
                        height: 80px;
                    }
                }

                .left {
                    position: absolute;
                    left: -40px;
                    top: 40px;
                    z-index: 999;
                    cursor: pointer;
                }

                .right {
                    position: absolute;
                    right: -40px;
                    top: 40px;
                    z-index: 999;
                    cursor: pointer;
                }
            }
        }

        .info {
            // border: 1px solid red;
            margin-left: 70px;
            height: 560px;
            width: 900px;
            position: relative;

            .basicInfoBox {

                // 标题
                .title {
                    width: 100%;
                    text-align: left;

                    .name {
                        width: 90%;
                        font-size: 30px;
                        font-weight: 500;
                        color: #333333;
                        line-height: 60px;
                        word-break: break-all;
                    }

                }

                .basicInfo {
                    margin-top: 60px;
                    font-size: 16px;
                    line-height: 60px;
                    width: 100%;

                    .basicInfo_title {
                        color: #999999;
                        font-size: 20px;
                        width: 30%;
                    }

                    .basicInfo_title2 {
                        color: #999999;
                        font-size: 20px;
                        width: 30%;
                    }

                    .basicInfo_value {
                        color: #333333;
                        font-size: 20px;
                        width: 70%;
                        word-break: break-all;
                    }

                    .basicInfo_value2 {
                        // border: 1px solid red;
                        color: #333333;
                        font-size: 20px;
                        width: 70%;
                        word-break: break-all;
                    }

                    .collectBtn {
                        // margin: 60px auto;
                        // margin-top: 10px;
                        // padding: 0 20px;
                        // background: #F8F8F8;
                        // border-radius: 10px;
                        width: 120px;
                        cursor: pointer;

                        img {
                            width: 17px;
                            height: 14px;
                        }

                        span {
                            margin-left: 5px;
                            font-size: 16px;
                            color: #999999;
                        }
                    }
                }
            }

            // 供应商
            .supplierInfo {
                // border: 1px solid red;
                width: 40%;
                height: 100%;
                // background: #F8F8F8;
                // border-radius: 40px;
                // padding: 5px 10px;
                // margin: 0 auto 20px;

                .supplierBox {
                    margin: 0 auto;
                    width: 230px;
                    height: 270px;
                    background: #F8F8F8;
                    border-radius: 40px;

                    img {
                        width: 66px;
                        height: 66px;
                        border-radius: 50%;
                        // margin-right: 20px;
                    }

                    .name {
                        color: #999999;
                        font-size: 16px;
                        margin-top: 24px;
                    }

                    .namevalue {
                        color: #000000;
                        font-size: 18px;
                        margin-top: 10px;
                        font-weight: 400;
                    }

                    .right {
                        margin-top: 44px;
                        cursor: pointer;

                        span {
                            color: #0363FA;
                            font-size: 16px;
                            font-weight: 400;
                            margin-right: 10px;
                        }

                        img {
                            width: 36px;
                            height: 36px;
                        }
                    }
                }

                // .collectBtn {
                //     margin: 60px auto;
                //     padding: 20px 70px;
                //     background: #F8F8F8;
                //     border-radius: 10px;
                //     width: 90px;
                //     cursor: pointer;

                //     img {
                //         width: 17px;
                //         height: 14px;
                //     }

                //     span {
                //         margin-left: 5px;
                //         font-size: 16px;
                //         color: #999999;
                //     }
                // }
            }

            .pageView {
                // position: absolute;
                // bottom: 0;
                // left: 0;
                color: #999999;
                font-size: 16px;
                line-height: 40px;
            }
        }

        /deep/ .el-dialog {
            border-radius: 10px;
        }
    }

    .productbasicInfo {
        margin-top: 50px;

        .title {
            font-size: 24px;
            color: #333333;
        }

        .infoBox {
            margin-top: 30px;

            // border: 1px solid red;
            .introTextBox {
                width: 40%;

                .title {
                    text-align: left;
                    font-size: 24px;
                    font-weight: 500;
                    color: #333333;
                    line-height: 60px;
                }

                .introText {
                    // border: 1px solid #409eff;

                    color: #999999;
                    font-size: 16px;
                    line-height: 25px;
                    padding: 0 30px 0 0;
                }
            }


            .argumentBox {
                width: 80%;

                .title {
                    text-align: left;
                    font-size: 24px;
                    font-weight: 500;
                    color: #333333;
                    line-height: 60px;
                }

                .argument {
                    // border: 1px solid #333333;
                    // width: 60%;
                    // margin-top: 20px;
                    font-size: 14px;
                    line-height: 30px;

                    .argument_item {
                        // width: 33.3333%;
                        width: 270px;
                        margin-right: 60px;

                        .argument_title {
                            color: #999999;
                        }

                        .argument_value {
                            color: #333333;
                        }
                    }
                }
            }

        }

    }

    .otherInfo {
        position: relative;
        .title {
            font-size: 24px;
            font-weight: 500;
            color: #333333;
        }
        .otherStyle {

            background: #F8F8F8;
            font-size: 16px;
            color: #999;
        }

        .inventory {
            border-radius: 10px;

            .count {
                border-radius: 10px 10px 0 0;
                padding: 0 25px;
                height: 80px;
                background: #F8F8F8;
                border-bottom: 1px solid #E5E4E4;

                .title {
                    font-size: 16px;
                    color: #999;
                }

                .btn {
                    width: 100px;
                    height: 40px;
                    background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
                    cursor: pointer;
                    line-height: 40px;
                    text-align: center;
                    color: #fff;
                    border-radius: 10px;
                    font-size: 16px;
                }
                .btn1 {
                    width: 100px;
                    height: 40px;
                    background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
                    line-height: 40px;
                    text-align: center;
                    color: #fff;
                    border-radius: 10px;
                    font-size: 16px;
                }
            }

            .record {
                border-radius: 0px 0px 10px 10px;
                padding: 0 25px;
                height: 80px;
                background: #F8F8F8;


                .title {
                    font-size: 16px;
                    color: #999;
                }

                .btn {
                    width: 100px;
                    height: 40px;
                    background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
                    cursor: pointer;
                    line-height: 40px;
                    text-align: center;
                    color: #fff;
                    border-radius: 10px;
                    font-size: 16px;
                }
                .btn1 {
                    width: 100px;
                    height: 40px;
                    background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
                    line-height: 40px;
                    text-align: center;
                    color: #fff;
                    border-radius: 10px;
                    font-size: 16px;
                }
            }
        }
        .filter {
            filter:blur(2px);
            height: 160px;
            width: 1280px;
        }
        .filterBtn{
            position: absolute;
            z-index: 99999999;
            top: 120px;
            left: 500px;
        }

    }

    .color-gb {
        width: 290px;
        height: 120px;
        margin: 0 auto 25px;
        text-align: center;
        line-height: 120px;
        color: #ffffff;

        .copy {
            margin-left: 10px;
            height: 20px;
            width: 20px;
            cursor: pointer;
            z-index: 999999;
        }
    }

    .btns {
        margin: 50px;

        .ModifyInfo {
            width: 160px;
            height: 60px;
            border-radius: 10px;
            background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
            text-align: center;
            color: #fff;
            line-height: 60px;
            cursor: pointer;
        }

        .AddSimilar {
            width: 160px;
            height: 60px;
            border-radius: 10px;
            background: #EFF4FA;
            text-align: center;
            color: #0363FA;
            line-height: 60px;
            cursor: pointer;
        }
    }

}
</style>
